import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import logo from 'assets/images/logo-blue-lp.webp';
import styles from './index.module.scss';


function Error() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/certificates');
  }, []);

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-sm"></div>

        <div className={`col-md-8 col-lg-6 col-xl-6 text-center ${styles.pgError}`}>
          <img src={logo} width="120" alt="Logo" />

          <div className={`container mt-5 mb-5 ${styles.textError}`}>
              <h2>Ooops!</h2>
              <h5>A página que você procura está temporariamente indisponível ou foi removida.</h5>
          </div>

          <div className="text-center">
            <Link to="/certificates" className={`btn mt-4 ${styles.linkVoltar}`}>Ir para página principal</Link>
          </div>
        </div>

        <div className="col-sm"></div>
      </div>
    </div>
  );
}

export default Error;
