import axios from 'axios';

let urlApi = process.env.REACT_APP_MODE == 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_LOCAL;

const config = {
  baseURL: urlApi,
};

const http = axios.create(config);

http.interceptors.request.use(
  function (config) {
    // const authToken = localStorage.getItem('auth_token');

    // if (authToken) {
    //   config.headers.common.Authorization = `Bearer ${authToken}`;
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default http;
