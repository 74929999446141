import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from 'pages/Home';
import Error from 'pages/Error'; 
import Profile from "pages/Profile";

const RoutesNavigation = () => {
    return (
      <>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/profile-beta-v1" element={<Profile />} /> */}
            {/* <Route path="/profile/:userId/:programId" element={<Profile />} /> */}
            <Route path="/certificates" element={<Home />}>
              <Route path=":certifiedHash" element={<Home />} />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  };
  
  export default RoutesNavigation;

