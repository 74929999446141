export const Types = {
  USER_SET: 'USER_SET',
  USER_RESET: 'USER_RESET',
  USER_TOKEN_SET: 'USER_TOKEN_SET',
  USER_PROFILE_SET: 'USER_PROFILE_SET',
  USER_REFRESH_TOKEN_SET: 'USER_REFRESH_TOKEN_SET',

  AGC_SET: 'AGC_SET',
  AGC_CREDORES_SET: 'AGC_CREDORES_SET',
  AGC_RESET: 'AGC_RESET',
};
