import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Spinner } from 'reactstrap';

import Avisos from 'components/Avisos';
import Navbar from 'components/Navbar';

import api from 'services/api';
import styles from './index.module.scss';

import bannerCertified from '../../data/certified/2.png';

function Home() {
  let { certifiedHash } = useParams();

  const [loading, setLoading] = useState(false);
  const [certified, setCertified] = useState({
    user_name: '',
    program_name: '',
    certified_file_url: '',
    certified_valid: false
  });

  useEffect(() => {
    const verifyCertified = async (certifiedHash: string) => {
      try {
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await api.get(`certified/${certifiedHash}/verify`);
  
        const certified = data?.certifiedData;
  
        setCertified(certified);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    if (certifiedHash) {
      verifyCertified(certifiedHash)
    }
  }, [])

  return (
    <>
      <Navbar page={'home'} />

      <div className='container'>
        <div className={`${styles.pageHome}`}>
          <div className={`mt-5 ${styles.textWelcome}`}>

            {loading ? 
              <div
                className="text-center mt-5 pt-5" 
              >
                <Spinner 
                  type="border"
                  style={{
                    color: '#2759a5',
                    height: '5rem',
                    width: '5rem'
                  }}
                />
              </div>
            : 
              (certified?.certified_valid ?
                <>
                  <h2 className="pl-5">{certified?.user_name}</h2>
                  <h5>certificado de conclusão do programa {certified?.program_name}.</h5>
                </>
              : 
                <>
                  <h2 className="pl-5">Nenhum certificado encontrado.</h2>
                </>
              )
            }
          </div>

          
          <div className={`${styles.boxCenter}`}>
            {certified?.certified_file_url ?
              <img 
                src={certified?.certified_file_url}
                width="100%"
                alt=""
              /> 
            : null}
          </div>
        </div>
      </div>
      
      <div className="p-5"></div>
    </>
  );
}

export default Home;
