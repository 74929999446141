import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";

import logo from 'assets/images/ima-tech-logo-blue.png';
import styles from './index.module.scss';

interface Props {
    page: string;
}

function Navbar({ page }: Props) {
  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light ${styles.topNavbar}`}>
        <div className="container">
          <Link to="/">
            <div className="navbar-brand">
              <img src={logo} width="120" alt="Logo" />
            </div>
          </Link>
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className={`navbar-nav ${styles.menuOptions}`}>
              
              <li className="nav-item">
                <a href="https://imatech.io" className={'nav-link'}>site da imã</a>
              </li>

              <li className="nav-item">
                <a href="https://imatech.io/para-empresas" className={'nav-link'}>programas</a>
              </li>

              <li className="nav-item">
                <a href="https://blog.imatech.io" className={'nav-link'}>blog</a>
              </li>

              <li className="nav-item">
                <a href="https://reserva.ink/lojinhadaima" className={'nav-link'}>loja</a>  
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
