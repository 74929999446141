import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';

import RoutesNavigation from './routes';

// import userService from './services/user';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      // const authToken = localStorage.getItem('auth_token');
      // const cpfUsuario = localStorage.getItem('cpf_usuario');

      // if (authToken && cpfUsuario) {
      //   const user = await userService.getUser(cpfUsuario);

      //   if (user) {
      //     userService.saveOld({ token: authToken, user });
      //   } else {
      //     userService.logout();
      //   }
      // } else {
      //   userService.logout();
      // }

      setLoading(false);
    }

    load();
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
    <>
      <RoutesNavigation />
    </>
  );
}

export default App;
