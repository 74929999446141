import { combineReducers } from 'redux';

import user from './user';
import agc from './agc';

const reducers = combineReducers({
  user,
  agc,
});

export default reducers;
